import { Link, graphql } from 'gatsby';
import type { FC } from 'react';
import styled from 'styled-components';
import { PageFrame } from '../components/PageFrame';
import { Colors } from '../utils/Colors';

const PostPreview = styled.div`
  small {
    color: ${Colors.Border};
  }
`;

type ProjectsProps = {
  data: GatsbyTypes.ProjectsQueryQuery;
};

export const Projects: FC<ProjectsProps> = ({
  data: { allContentfulProject, site },
}) => {
  return (
    <PageFrame
      description={site?.siteMetadata?.description}
      title={`${site?.siteMetadata?.title ?? ''}.projects`}
    >
      <h1>Projects</h1>

      {allContentfulProject.edges.map(({ node }) => (
        <PostPreview key={node.id}>
          <h2>
            <Link to={node.gatsbyPath ?? '/projects'}>{node.title}</Link>{' '}
            <small>&mdash; launched {node.launchDate?.toLowerCase()}</small>
          </h2>
        </PostPreview>
      ))}
    </PageFrame>
  );
};
Projects.displayName = 'Projects';
export default Projects;

export const query = graphql`
  query ProjectsQuery {
    site {
      siteMetadata {
        description
        title
      }
    }
    allContentfulProject(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          id
          gatsbyPath(filePath: "/projects/{ContentfulProject.slug}")
          title
          launchDate(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
